import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { system } from 'styled-system';
import { Section } from '../components/Section';
import { Container } from '../components/Container';
import { Box } from '../components/Box';
import { VisuallyHidden } from '../components/VisuallyHidden';
import { Cure, The, Jones } from '../components/Labels';
import { Column, Grid } from '../components/Grid';
import { Rotate } from '../components/Rotate';
import { Text } from '../components/Text';
import { Stack } from '../components/Stack';

export function Home() {
  return (
    <Section id="home" background="var(--black)" py={0}>
      <VisuallyHidden as="h1" className="sr-only">
        Cure The Jones
      </VisuallyHidden>
      <Container fluid>
        <Box
          position="relative"
          display="flex"
          alignItems={[null, null, null, 'center']}
          flexDirection="column"
          justifyContent="center"
          py={[5, 5, 5, 6]}
        >
          <Grid gridGap={[2, 4]} gridTemplateColumns="repeat(12, 1fr)">
            <Column
              gridColumn={[
                'span 12 / span 12',
                'span 12 / span 12',
                'span 12 / span 12',
                '3 / span 4'
              ]}
            >
              <StaticImage
                src="../images/covers/cure-the-jones.jpg"
                alt="Cure The Jones album cover"
                placeholder="blurred"
                objectFit="contain"
                loading="eager"
                imgStyle={{ border: '3px solid var(--white)' }}
              />
            </Column>
            <AlbumNameColumn
              gridColumn={[
                'span 12 / span 12',
                'span 12 / span 12',
                'span 12 / span 12',
                'span 4 / span 4'
              ]}
            >
              <Rotate value={2}>
                <Box height={['4rem', '7vw']} my={['0.65rem', '1rem']}>
                  <Cure />
                </Box>
              </Rotate>
              <Rotate value={-1}>
                <Box
                  height={['4rem', '7vw']}
                  my={['0.65rem', '1rem']}
                  ml="1rem"
                >
                  <The />
                </Box>
              </Rotate>
              <Rotate value={-1}>
                <Box height={['4rem', '7vw']} my={['0.65rem', '1rem']}>
                  <Jones />
                </Box>
              </Rotate>
            </AlbumNameColumn>
          </Grid>
          <CtaWrapper
            position={[null, null, null, 'absolute']}
            right={[null, null, null, 0]}
            mt={[3, 3, 0]}
            transform={[null, null, null, 'translateX(50%) rotate(90deg)']}
            transformOrigin={[null, null, null, 'top']}
          >
            <Stack direction="row" gap="1rem">
              <CtaLink
                href="https://mamasgun.bandcamp.com/merch"
                target="_blank"
              >
                <CtaText
                  as="span"
                  color="var(--white)"
                  fontWeight={700}
                  fontSize="1.25rem"
                >
                  Purchase
                </CtaText>
              </CtaLink>
              <CtaLink href="https://slinky.to/CureTheJones" target="_blank">
                <CtaText
                  as="span"
                  color="var(--white)"
                  fontWeight={700}
                  fontSize="1.25rem"
                >
                  Listen
                </CtaText>
              </CtaLink>
            </Stack>
          </CtaWrapper>
        </Box>
      </Container>
    </Section>
  );
}

const AlbumNameColumn = styled(Column)`
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  padding: 1rem 0;
`;

const CtaWrapper = styled(Box)<{
  transform: null | string | Array<string | null>;
  transformOrigin: null | string | Array<string | null>;
}>`
  ${system({
    transform: true,
    transformOrigin: true
  })}
`;

const CtaText = styled(Text)`
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 5px;
  color: var(--white);
`;

const CtaLink = styled.a`
  &:focus-visible {
    outline: 5px solid blue;
  }
`;

import React from 'react';
import { Text } from './Text';
import { Box } from './Box';
import { useAccordionItem } from './Accordion';
import styled from 'styled-components';

interface AccordionItemProps {
  children: React.ReactNode;
  id: string;
  label: string;
}

export function AccordionItem({ children, id, label }: AccordionItemProps) {
  const { isOpen, buttonProps } = useAccordionItem(id);
  return (
    <AccordionItemWrapper>
      <AccordionItemButton {...buttonProps}>
        <Text as="span" fontSize={['1.5rem', '2rem']} fontWeight={700}>
          {label}
        </Text>
        <AccordionButtonIcon isOpen={isOpen} />
      </AccordionItemButton>
      {isOpen && <Box my={3}>{children}</Box>}
    </AccordionItemWrapper>
  );
}

function AccordionButtonIcon({ isOpen }: { isOpen: boolean }) {
  return (
    <Box height="2rem" width="2rem">
      <StyledSVG role="img" viewBox="0 0 21 21">
        <path d="M0 10.5L21 10.5" />
        <path d="M10.5 0L10.5 21" style={{ opacity: isOpen ? 0 : 1 }} />
      </StyledSVG>
    </Box>
  );
}

const StyledSVG = styled.svg`
  height: 100%;
  width: 100%;
  & path {
    stroke: var(--black);
    stroke-width: 4;
  }
`;

const AccordionItemWrapper = styled(Box)`
  border-top: 5px solid var(--black);
  &:last-child {
    border-bottom: 5px solid var(--black);
  }
`;

const AccordionItemButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  text-align: left;
  cursor: pointer;
  &.focus-visible {
    outline: 5px solid blue;
  }
`;

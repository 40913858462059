import React, { useState, useRef } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { AlbumDialog } from './AlbumDialog';
import { Column, Grid } from './Grid';
import { Text } from './Text';
import styled from 'styled-components';

interface AlbumLink {
  name: string;
  href: string;
}

interface GridAlbum {
  name: string;
  releaseDate: string;
  alt: string;
  image: IGatsbyImageData;
  notes: string[];
  links: AlbumLink[];
}

export function AlbumGridListItem({
  name,
  releaseDate,
  alt,
  notes,
  links,
  image
}: GridAlbum) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const albumListButtonRef = useRef(null);

  return (
    <Column
      gridColumn={[
        'span 12 / span 12',
        'span 12 / span 12',
        'span 12 / span 12',
        '3 / span 10'
      ]}
    >
      <Grid
        as={AlbumListButton}
        gridGap={3}
        gridTemplateColumns={[
          'repeat(12, 1fr)',
          'repeat(12, 1fr)',
          'repeat(12, 1fr)',
          'repeat(10, 1fr)'
        ]}
        onClick={() => setIsDialogOpen(true)}
        ref={albumListButtonRef}
      >
        <Column
          gridColumn={[
            'span 2 / span 2',
            'span 2 / span 2',
            'span 2 / span 2',
            'span 1 / span 1'
          ]}
        >
          <GatsbyImage image={image} alt={alt} />
        </Column>
        <AlbumNameColumn
          gridColumn={[
            'span 10 / span 10',
            'span 8 / span 8',
            'span 8 / span 8',
            'span 9 / span 9'
          ]}
        >
          <Text
            as="span"
            fontSize={['1.5rem', '2rem', '2rem', '3rem']}
            fontWeight={700}
            textAlign="left"
          >
            {name}
          </Text>
        </AlbumNameColumn>
      </Grid>
      <AlbumDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        albumName={name}
        releaseDate={releaseDate}
        image={image}
        imageAlt={alt}
        notes={notes}
        links={links}
        returnFocusTo={albumListButtonRef}
      />
    </Column>
  );
}

const AlbumListButton = styled.button`
  width: 100%;
  background-color: transparent;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  border-bottom: 5px solid var(--black);
  transition: background 350ms ease, color 350ms ease;
  cursor: pointer;
  &:hover {
    background-color: var(--black);
    color: var(--white);
  }
  &.focus-visible {
    outline: 5px solid blue;
    outline-offset: 5px;
  }
`;

const AlbumNameColumn = styled(Column)`
  display: flex;
  align-items: center;
  height: 100%;
`;

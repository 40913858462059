import React from 'react';
import { Accordion } from '../components/Accordion';
import { AccordionItem } from '../components/AccordionItem';
import { Container } from '../components/Container';
import { Section } from '../components/Section';
import { SectionHeading } from '../components/SectionHeading';
import { Stack } from '../components/Stack';
import { Text } from '../components/Text';

const contactInfo = [
  {
    area: 'Booking',
    contacts: [
      { label: 'ROW', value: 'carly@candelion.com' },
      { label: 'Germany', value: 'johannes@golden-ticket.de' },
      { label: 'France', value: 'blmusique@gmail.com' },
      { label: 'Holland', value: 'wouter@greenhousetalent.nl' },
      { label: 'Belgium', value: 'kristof@greenhousetalent.be' },
      { label: 'Spain', value: 'bigmic@wokmusic.net' }
    ]
  },
  {
    area: 'UK Press',
    contacts: [
      { label: null, value: 'louise@partisanpr.com / tom@partisanpr.com' }
    ]
  },
  {
    area: 'Radio',
    contacts: [{ label: null, value: 'Chris Young – Nature Music' }]
  },
  {
    area: 'Labels',
    contacts: [
      { label: 'UK', value: 'Candelion' },
      { label: 'Germany', value: 'Legere Records' },
      { label: 'Benelux', value: 'Go Entertainment' },
      { label: 'Japan', value: 'P-Vine Records' },
      { label: 'Korea', value: 'Private Curve Southeast' },
      { label: 'Asia', value: 'Love Da Records' },
      { label: 'North America', value: 'Ubiquity Records' }
    ]
  }
];

export function Contact() {
  return (
    <Section id="contact">
      <Container fluid>
        <SectionHeading textAlign={['left', 'left', 'left', 'right']}>
          Contact
        </SectionHeading>
        <Text fontSize={['1rem', '2rem']} fontWeight={700} mb="1rem">
          For all enquiries (including live booking): <br />
          carly@mg-management.co.uk
        </Text>
        <Accordion>
          {contactInfo.map(({ area, contacts }, index) => {
            return (
              <AccordionItem
                key={area}
                id={`${area.toLowerCase()}-${index}`}
                label={area}
              >
                <Stack gap="0.5rem">
                  {contacts.map(({ label, value }) => {
                    return (
                      <Text>{`${label ? `${label}: ` : ''}${value}`}</Text>
                    );
                  })}
                </Stack>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Container>
    </Section>
  );
}

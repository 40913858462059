import React, { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Container } from '../components/Container';
import { Section } from '../components/Section';
import { SectionHeading } from '../components/SectionHeading';
import { Box } from '../components/Box';

export function Tour() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//widget.songkick.com/609559/widget.js';
    document.body.appendChild(script);
  }, []);

  return (
    <Section id="tour" minHeight="90vh">
      <Container fluid>
        <SectionHeading color="var(--white)">Tour</SectionHeading>
      </Container>
      <Box
        position="absolute"
        zIndex={-10}
        top={0}
        left={0}
        width="100%"
        height="100%"
        background="var(--pink)"
      />
      {/* <Box
        position="absolute"
        zIndex={-20}
        top={0}
        left={0}
        width="100%"
        height="100%"
      >
        <StaticImage
          src="../images/tour-background.jpeg"
          alt=""
          layout="fullWidth"
          style={{ height: '100%' }}
        />
      </Box> */}
      <a
        href="https://www.songkick.com/artists/609559"
        className="songkick-widget"
        data-theme="dark"
        data-detect-style="true"
        data-font-color="#ffffff"
        data-background-color="transparent"
      />
    </Section>
  );
}

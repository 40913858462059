import React from 'react';
import { Box } from './Box';

interface ContainerProps {
  children: React.ReactNode;
  fluid?: boolean;
}

export function Container({ children, fluid = false }: ContainerProps) {
  return (
    <Box
      position="relative"
      px={3}
      width="100%"
      mx="auto"
      maxWidth={fluid ? null : ['38rem', '38rem', '38rem', '38rem']}
    >
      {children}
    </Box>
  );
}

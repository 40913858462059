import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Container } from './Container';
import { Box } from './Box';
import { Column, Grid } from './Grid';
import { Heading } from './Heading';
import { Text } from './Text';
import { LinkButton } from './Button';
import { Stack } from './Stack';

interface AlbumDialogProps {
  isOpen: boolean;
  onClose: () => void;
  albumName: string;
  releaseDate: string;
  image: IGatsbyImageData;
  imageAlt: string;
  notes: string[];
  links: { name: string; href: string }[];
  returnFocusTo: React.RefObject<HTMLElement>;
}

export function AlbumDialog({
  isOpen,
  onClose,
  albumName,
  releaseDate,
  image,
  imageAlt,
  notes,
  links,
  returnFocusTo
}: AlbumDialogProps) {
  const closeButtonRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.height = '100vh';
      // This prevents iOS safari address bar gap at bottom
      // of screen.
      document.documentElement.style.height = '100vh';
      document.body.style.overflowY = 'hidden';
      closeButtonRef.current.focus();
    } else {
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('height');
      document.documentElement.style.removeProperty('height');
      document.body.style.removeProperty('overflow-y');
      const scrollY = document.body.style.top;
      document.body.style.removeProperty('top');
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [isOpen]);

  if (typeof window === 'undefined') {
    return null;
  }

  return isOpen
    ? ReactDOM.createPortal(
        <>
          <DialogBackground onClick={() => onClose()} />
          <DialogWrapper role="dialog" margin={[0, 0, 0, '3rem 12rem']}>
            <Box position="sticky" top={0} background="var(--white)" zIndex={1}>
              <Container fluid>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  height="4rem"
                >
                  <CloseButton ref={closeButtonRef} onClick={onClose}>
                    Close
                  </CloseButton>
                </Box>
              </Container>
            </Box>
            <Container fluid>
              <Box pb={['1rem', '1rem', '1rem', 0]}>
                <Grid gridGap={[3, 4]} gridTemplateColumns="repeat(12, 1fr)">
                  <Column
                    gridColumn={[
                      'span 12 / span 12',
                      'span 12 / span 12',
                      'span 12 / span 12',
                      'span 5 / span 5'
                    ]}
                  >
                    <GatsbyImage
                      image={image}
                      alt={imageAlt}
                      objectFit="contain"
                    />
                  </Column>
                  <Column
                    gridColumn={[
                      'span 12 / span 12',
                      'span 12 / span 12',
                      'span 12 / span 12',
                      'span 7 / span 7'
                    ]}
                  >
                    <Stack gap="2rem">
                      <Box>
                        <Heading
                          level={2}
                          fontSize={['2rem', '3rem']}
                          fontWeight={700}
                          lineHeight={1}
                        >
                          {albumName}
                        </Heading>
                        <Text
                          fontSize="2rem"
                          fontWeight={700}
                          color="var(--gray)"
                          lineHeight={1}
                        >
                          {new Intl.DateTimeFormat('en-GB', {
                            month: 'long',
                            year: 'numeric'
                          }).format(new Date(releaseDate))}
                        </Text>
                      </Box>
                      <Box>
                        <Stack gap="0.5rem">
                          {notes.map((note) => (
                            <Text>{note}</Text>
                          ))}
                        </Stack>
                      </Box>
                      <Grid gridGap={2} gridTemplateColumns="repeat(2, 1fr)">
                        {links.map(({ name, href }) => (
                          <Column gridColumn={['span 1 / span 1']}>
                            <LinkButton target="_blank" href={href}>
                              {name}
                            </LinkButton>
                          </Column>
                        ))}
                      </Grid>
                    </Stack>
                  </Column>
                </Grid>
              </Box>
            </Container>
          </DialogWrapper>
        </>,
        document.body
      )
    : null;
}

const DialogWrapper = styled(Box)`
  position: fixed;
  inset: 0;
  background-color: var(--white);
  z-index: 50;
  border: 5px solid var(--black);
  overflow-y: scroll;
`;

const DialogBackground = styled.div`
  position: fixed;
  inset: 0;
  z-index: 40;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
`;

const CloseButton = styled.button`
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  background: none;
  font-size: inherit;
  cursor: pointer;
  &:focus {
    outline: 5px solid blue;
  }
`;

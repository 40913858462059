import React from 'react';
import styled from 'styled-components';
import { position, PositionProps, TextAlignProps, system } from 'styled-system';
import { Heading } from './Heading';

interface SectionHeadingProps extends PositionProps, TextAlignProps {
  children: React.ReactNode;
  transform?: null | string | string[];
  transformOrigin?: null | string | string[];
  color?: string;
}

export function SectionHeading({
  children,
  transform,
  transformOrigin,
  color,
  textAlign,
  ...rest
}: SectionHeadingProps) {
  return (
    <HeadingWrapper
      transform={transform}
      transformOrigin={transformOrigin}
      {...rest}
    >
      <Heading
        level={1}
        fontSize={['3rem', '6rem']}
        fontWeight={700}
        color={color}
        textAlign={textAlign}
        mb={['1.5rem', null]}
      >
        {children}
      </Heading>
    </HeadingWrapper>
  );
}

const HeadingWrapper = styled.div<
  Pick<SectionHeadingProps, 'transform' | 'transformOrigin'>
>`
  line-height: 1;
  ${position}
  ${system({
    transform: true,
    transformOrigin: true
  })}
`;

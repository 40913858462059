import React, { useEffect } from 'react';
import jquery from 'jquery';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Button } from './Button';
import { Column, Grid } from './Grid';
import { Input } from './Input';
import { Text } from './Text';

interface Values {
  firstName?: string;
  lastName?: string;
  email?: string;
}

type Errors = {
  [P in keyof Values]: string | null;
};

async function submit({ firstName, lastName, email }: Values) {
  return new Promise((res, rej) => {
    // Can't use `fetch` due to CORS issues, MC uses jQuery under the hood,
    // so trying to mimic their process as much as possible without having
    // to load their form widget.
    (jquery as any).ajax({
      type: 'GET',
      url: 'https://mamasgun.us8.list-manage.com/subscribe/post-json?u=d1fcbd2e57af5e09668c937f0&amp;id=2ba09daee6&c=?',
      data: {
        FNAME: firstName,
        LNAME: lastName,
        EMAIL: email
      },
      cache: false,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      error: function () {
        rej(false);
      },
      success: function ({ result }) {
        if (result === 'error') {
          rej(false);
        } else {
          res(true);
        }
      }
    });
  });
}

export function NewsletterSignup() {
  const { values, errors, handleSubmit, handleChange, isSubmitting, isValid } =
    useFormik<Values>({
      initialValues: {
        firstName: '',
        lastName: '',
        email: ''
      },
      onSubmit: async (values) => {
        try {
          await submit(values);
          toast.success(
            'Thanks for signing up! Check your e-mail for confirmation!'
          );
        } catch (e) {
          toast.error("Oh no! That didn't work, try again!");
        }
      },
      validate: ({ firstName, lastName, email }) => {
        const errors: Errors = {};

        if (!firstName) {
          errors.firstName = 'This field is required!';
        }

        if (!lastName) {
          errors.lastName = 'This field is required!';
        }

        if (!email) {
          errors.email = 'This field is required!';
        }

        return errors;
      }
    });

  return (
    <form onSubmit={handleSubmit}>
      <Grid gridGap={3} gridRowGap={4} gridTemplateColumns="repeat(12, 1fr)">
        <Column
          gridColumn={[
            'span 12 / span 12',
            'span 12 / span 12',
            'span 12 / span 12',
            '3 / span 8'
          ]}
        >
          <Text fontSize={['1rem', '2rem']} fontWeight={700}>
            Subscribe to the newsletter to receive the latest news about
            releases, tours, etc.
          </Text>
        </Column>
        <Column
          gridColumn={[
            'span 12 / span 12',
            'span 12 / span 12',
            'span 12 / span 12',
            '3 / span 4'
          ]}
        >
          <Input
            label="First Name"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            validationError={errors.firstName}
          />
        </Column>
        <Column
          gridColumn={[
            'span 12 / span 12',
            'span 12 / span 12',
            'span 12 / span 12',
            '7 / span 4'
          ]}
        >
          <Input
            label="Last Name"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            validationError={errors.lastName}
          />
        </Column>
        <Column
          gridColumn={[
            'span 12 / span 12',
            'span 12 / span 12',
            'span 12 / span 12',
            '3 / span 8'
          ]}
        >
          <Input
            type="email"
            label="E-mail"
            name="email"
            value={values.email}
            onChange={handleChange}
            validationError={errors.email}
          />
        </Column>
        <Column
          gridColumn={[
            'span 12 / span 12',
            'span 12 / span 12',
            'span 12 / span 12',
            '3 / span 2'
          ]}
        >
          <Button
            type="submit"
            fullWidth
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </Column>
      </Grid>
    </form>
  );
}

import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { AlbumGridListItem } from './AlbumGridListItem';
import { Grid } from './Grid';

interface Edge {
  node: {
    name: string;
    childImageSharp: { gatsbyImageData: IGatsbyImageData };
  };
}

interface CoversData {
  edges: Edge[];
}

const albumMap = {
  'cure-the-jones': {
    name: 'Cure The Jones',
    release: '2022-04-01T00:00:00.000Z',
    notes: [
      'Personnel: Andy Platts, Terry Lewis, Dave Oliver, Cameron Dawson, Chris Boot'
    ],
    links: [
      {
        name: 'CD/Vinyl',
        href: 'https://mamasgun.bandcamp.com/merch'
      }
    ]
  },
  'tin-pan-sessions': {
    name: 'Tin Pan Sessions EP',
    release: '2020-01-20T00:00:00.000Z',
    notes: [
      'Personnel: Andy Platts, Terry Lewis, Dave Oliver, Cameron Dawson, Chris Boot'
    ],
    links: [
      {
        name: 'Apple Music',
        href: 'https://music.apple.com/ag/album/the-tin-pan-sessions-ep/1538565124'
      },
      {
        name: 'Amazon',
        href: 'https://www.amazon.co.uk/Tin-Pan-Sessions-Mamas-Gun/dp/B08MX6V2XW'
      },
      {
        name: 'Spotify',
        href: 'https://open.spotify.com/album/4X6QCi4Cu32YYU5eu8Fs3U?si=uIist-HYT7iBmMvf7wnZTA'
      }
    ]
  },
  'golden-days': {
    name: 'Golden Days',
    release: '2018-05-18T00:00:00.000Z',
    notes: [
      'Producer: Mamas Gun',
      'Recorded at: Roffey Hall',
      'Singles: London Girls, I Need A Win, You Make My Life A Better Place, On the Wire',
      'Personnel: Andy Platts, Terry Lewis, Dave Oliver, Cameron Dawson, Chris Boot'
    ],
    links: [
      {
        name: 'CD/Vinyl',
        href: 'https://mamasgun.bandcamp.com/merch'
      },
      {
        name: 'Spotify',
        href: 'http://smarturl.it/mgGoldenDaysStream/spotify'
      },
      {
        name: 'Bandcamp',
        href: 'https://mamasgun.bandcamp.com/merch'
      },
      {
        name: 'Apple Music',
        href: 'https://smarturl.it/mgGoldenDaysStream/applemusic'
      },
      {
        name: 'iTunes',
        href: 'https://smarturl.it/mgGoldenDaysStream/itunes'
      },
      {
        name: 'Amazon Music',
        href: 'https://smarturl.it/mgGoldenDaysStream/az'
      }
    ]
  },
  'room-service': {
    name: 'Room Service EP',
    release: '2016-02-26T00:00:00.000Z',
    notes: [
      'Personnel: Andy Platts, Terry Lewis, Dave Oliver, Jack Pollitt, Cameron Dawson'
    ],
    links: [
      {
        name: 'Apple Music',
        href: 'https://music.apple.com/gb/album/room-service-ep/1086607238'
      },
      {
        name: 'Amazon',
        href: 'https://www.amazon.co.uk/Room-Service-Mamas-Gun/dp/B01C38PP42'
      },
      {
        name: 'Spotify',
        href: 'https://open.spotify.com/album/4O8LTZBx2ck8izV6b3P2BF?si=b3YO5GM5SpCML3muGbSpVw'
      }
    ]
  },
  'cheap-hotel': {
    name: 'Cheap Hotel',
    release: '2014-09-14T00:00:00.000Z',
    notes: [
      'Producer: Julian Simmons & Andy Platts',
      'Recorded at: Cake & The Vicarage',
      'Singles: Red Cassette, Hello Goodnight',
      'Personnel: Andy Platts, Terry Lewis, Dave Oliver, Jack Pollitt, Cameron Dawson'
    ],
    links: [
      {
        name: 'CD/Vinyl',
        href: 'https://mamasgun.bandcamp.com/merch'
      },
      {
        name: 'Spotify',
        href: 'http://smarturl.it/mgCheapHotelstream/spotify'
      },
      {
        name: 'Apple Music',
        href: 'https://smarturl.it/mgCheapHotelstream/applemusic'
      },
      {
        name: 'iTunes',
        href: 'https://smarturl.it/mgCheapHotelstream/itunes'
      },
      {
        name: 'Amazon',
        href: 'https://smarturl.it/mgCheapHotelstream/az'
      },
      {
        name: 'Deezer',
        href: 'https://smarturl.it/mgCheapHotelstream/deezer'
      }
    ]
  },
  'life-and-soul': {
    name: 'The Life & Soul',
    release: '2011-05-24T00:00:00.000Z',
    notes: [
      'Producer: Martin Terefe & Andreas Olsson',
      'Recorded at: Kensal Town Studio',
      'Singles: Reconnection, On A String, The Art',
      'Personnel: Andy Platts, Terry Lewis, Dave Oliver, Rex Horan, Jack Pollitt'
    ],
    links: [
      {
        name: 'Spotify',
        href: 'http://smarturl.it/mgLifeAndSoulStream/spotify'
      },
      {
        name: 'Apple Music',
        href: 'http://smarturl.it/mgLifeAndSoulStream/applemusic'
      },
      {
        name: 'iTunes',
        href: 'http://smarturl.it/mgLifeAndSoulStream/itunes'
      },
      {
        name: 'Amazon',
        href: 'http://smarturl.it/mgLifeAndSoulStream/az'
      },
      {
        name: 'Deezer',
        href: 'http://smarturl.it/mgLifeAndSoulStream/deezer'
      }
    ]
  },
  'routes-to-riches': {
    name: 'Routes To Riches',
    release: '2009-09-27T00:00:00.000Z',
    notes: [
      'Producer: Julian Simmons & Andy Platts',
      'Recorded at: Din Sound',
      'Singles: Pots of Gold, You Are The Music, Let’s Find A Way, Finger On it / Wishing (Double A Side)',
      'Personnel: Andy Platts, Terry Lewis, Dave Oliver, Rex Horan, Jack Pollitt'
    ],
    links: [
      {
        name: 'Purchase',
        href: 'https://mamasgun.bandcamp.com/merch'
      },
      {
        name: 'Spotify',
        href: 'http://smarturl.it/mgRTRstream/spotify'
      },
      {
        name: 'Apple Music',
        href: 'http://smarturl.it/mgRTRstream/applemusic'
      },
      {
        name: 'iTunes',
        href: 'http://smarturl.it/mgRTRstream/itunes'
      },
      {
        name: 'Amazon Music',
        href: 'http://smarturl.it/mgRTRstream/az'
      },
      {
        name: 'Deezer',
        href: 'http://smarturl.it/mgRTRstream/deezer'
      }
    ]
  },
  'live-pizza-express': {
    name: 'At PizzaExpress Live - In London',
    release: '2023-05-19T00:00:00.000Z',
    notes: [
      'Producer: Mamas Gun',
      'Recorded at: PizzaExpress Live - In London (Holborn)',
      'Personnel: Andy Platts, Terry Lewis, Dave Oliver, Cameron Dawson, Chris Boot'
    ],
    links: [
      {
        name: 'Bandamp',
        href: 'https://mamasgun.bandcamp.com/merch'
      },
      {
        name: 'Spotify',
        href: 'https://open.spotify.com/album/2QFUJvuhdYm87fDhCrr8Mb?si=YTueFzjwSIiJGqwURs6BdA'
      },
      {
        name: 'Rough Trade',
        href: 'https://www.roughtrade.com/en-gb/product/mamas-gun/at-pizzaexpress-live-in-london-2'
      },
      {
        name: 'Amazon Music',
        href: 'https://www.amazon.co.uk/At-PizzaExpress-Live-Mamas-Gun/dp/B0BXHJY3KD'
      }
    ]
  }
};

type Album = {
  gatsbyImageData: IGatsbyImageData;
} & typeof albumMap[keyof typeof albumMap];

function prepareAlbums(covers: CoversData): Album[] {
  return covers.edges
    .map((cover) => {
      return {
        ...cover.node.childImageSharp,
        ...albumMap[cover.node.name]
      };
    })
    .sort(
      (a, b) => new Date(b.release).getTime() - new Date(a.release).getTime()
    );
}

export function AlbumGridList() {
  const { covers } = useStaticQuery<{ covers: CoversData }>(graphql`
    query {
      covers: allFile(filter: { dir: { regex: "/covers/" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
            name
          }
        }
      }
    }
  `);

  const albumData = prepareAlbums(covers);

  return (
    <AlbumGrid gridGap={3} gridTemplateColumns="repeat(12, 1fr)">
      {albumData.map(({ name, gatsbyImageData, release, notes, links }) => {
        return (
          <AlbumGridListItem
            name={name}
            releaseDate={release}
            alt={`${name} cover`}
            image={gatsbyImageData}
            notes={notes}
            links={links}
          />
        );
      })}
    </AlbumGrid>
  );
}

const AlbumGrid = styled(Grid)``;

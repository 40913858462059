import React from 'react';
import styled from 'styled-components';
import { fontSize, FontSizeProps } from 'styled-system';
import { Box } from './Box';
import { Spinner } from './Spinner';

type ButtonProps = {
  children: React.ReactNode;
  fullWidth?: boolean;
  loading?: boolean;
} & FontSizeProps &
  React.ComponentPropsWithoutRef<'button'>;

const baseStyles = `
  ${fontSize}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  outline: none;
  background: none;
  border: 5px solid var(--black);
  font-weight: 700;
  cursor: pointer;
  font-size: inherit;
  &:hover {
    background: var(--black);
    color: var(--white);
  }
  &.focus-visible {
    border: 5px solid blue;
  }
`;

export function Button({ children, loading = false, ...rest }: ButtonProps) {
  return (
    <StyledButton disabled={loading} {...rest}>
      <ButtonText loading={loading}>{children}</ButtonText>
      {loading && (
        <Box as="span" position="absolute" height="2rem" width="2rem">
          <Spinner />
        </Box>
      )}
    </StyledButton>
  );
}

export const LinkButton = styled.a`
  ${baseStyles}
`;

const StyledButton = styled.button<{ fullWidth?: boolean } & FontSizeProps>`
  ${baseStyles}
`;

const ButtonText = styled.span<{ loading: boolean }>`
  opacity: ${({ loading }) => (loading ? 0 : 1)};
`;

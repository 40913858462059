import React, { useState, useContext } from 'react';

interface AccordionProps {
  children: React.ReactNode;
}

export const AccordionContext = React.createContext(null);

export function useAccordionItem(id: string) {
  const { activeId, toggleActiveId } = useContext(AccordionContext);
  return {
    isOpen: id === activeId,
    buttonProps: {
      id,
      'aria-expanded': id === activeId,
      'aria-controls': `${id}-section`,
      onClick: () => toggleActiveId(id)
    },
    sectionProps: { id: `${id}-section`, role: 'region', 'aria-labelledby': id }
  };
}

export function Accordion({ children }: AccordionProps) {
  const [activeId, setActiveId] = useState<null | string>(null);
  return (
    <AccordionContext.Provider
      value={{
        activeId,
        toggleActiveId: (id: string) => setActiveId(id === activeId ? null : id)
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
}

import React from 'react';
import { Container } from '../components/Container';
import { NewsletterSignup } from '../components/NewsletterSignup';
import { Section } from '../components/Section';
import { SectionHeading } from '../components/SectionHeading';

export function Newsletter() {
  return (
    <Section id="newsletter" background="lightgray">
      <Container fluid>
        <SectionHeading
          position={[null, null, null, 'absolute']}
          right={0}
          transform={[
            null,
            null,
            null,
            'translateX(calc(100% - 1rem)) rotate(90deg)'
          ]}
          transformOrigin={[null, null, null, 'top left']}
        >
          Newsletter
        </SectionHeading>
        <NewsletterSignup />
      </Container>
    </Section>
  );
}

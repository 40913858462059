import React, { useState } from 'react';
import styled from 'styled-components';
import FocusTrap from 'focus-trap-react';
import { Box } from './Box';
import { Container } from './Container';
import { Logo } from './Logo';
import { NavMenu } from './NavMenu';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <FocusTrap
      active={isMenuOpen}
      focusTrapOptions={{ initialFocus: '#nav-menu-button' }}
    >
      <StyledHeader>
        <Container fluid>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="4rem"
          >
            <Logo />
            <MenuButton
              id="nav-menu-button"
              aria-haspopup="true"
              aria-controls="nav-menu"
              aria-expanded={isMenuOpen}
              onClick={() => setIsMenuOpen((prev) => !prev)}
            >
              {isMenuOpen ? 'Close' : 'Menu'}
            </MenuButton>
          </Box>
          {isMenuOpen && (
            <NavMenu onMenuItemClick={() => setIsMenuOpen(false)} />
          )}
        </Container>
      </StyledHeader>
    </FocusTrap>
  );
}

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  color: var(--white);
  text-transform: uppercase;
`;

const MenuButton = styled.button`
  text-transform: inherit;
  font-weight: 700;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  &.focus-visible {
    outline: 5px solid blue;
  }
`;

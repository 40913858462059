import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Seo } from '../components/Seo';
import { Layout } from '../components/Layout';
import { Home } from '../sections/Home';
import { Music } from '../sections/Music';
import { Tour } from '../sections/Tour';
import { Contact } from '../sections/Contact';
import { Newsletter } from '../sections/Newsletter';

export default function Index() {
  return (
    <Layout>
      <Seo />
      <Toaster
        position="bottom-left"
        toastOptions={{
          duration: 4000,
          success: {
            iconTheme: {
              primary: 'var(--green)',
              secondary: 'var(--white)'
            },
            style: {
              border: '5px solid var(--green)'
            }
          },
          error: {
            iconTheme: { primary: 'var(--red)', secondary: 'var(--white)' },
            style: {
              color: 'var(--red)',
              border: '5px solid var(--red)'
            }
          },
          style: {
            padding: '1rem',
            borderRadius: '0px',
            backgroundColor: 'var(--white)'
          }
        }}
      />
      <Home />
      <Music />
      <Tour />
      <Contact />
      <Newsletter />
    </Layout>
  );
}

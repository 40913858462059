import React from 'react';
import styled from 'styled-components';
import { fontSize, FontSizeProps } from 'styled-system';

type InputProps = {
  label: string;
  validationError?: string;
} & React.ComponentPropsWithoutRef<'input'>;

export function Input({
  type = 'text',
  label,
  validationError,
  ...rest
}: InputProps) {
  return (
    <Label fontSize={['1rem', '1.5rem']}>
      {label}
      <StyledInput
        type={type}
        fontSize={['1rem', '2rem']}
        invalid={!!validationError}
        {...rest}
      />
    </Label>
  );
}

const Label = styled.label<FontSizeProps>`
  ${fontSize}
  font-weight: 700;
  cursor: pointer;
`;

const StyledInput = styled.input<{ invalid: boolean } & FontSizeProps>`
  ${fontSize}
  outline: none;
  border: 5px solid
    ${({ invalid }) => (invalid ? 'var(--red)' : 'var(--black)')};
  border-radius: 0;
  background: none;
  padding: 1rem;
  width: 100%;
  &.focus-visible {
    border: 5px solid blue;
  }
`;

import React from 'react';
import { Section } from '../components/Section';
import { Container } from '../components/Container';
import { AlbumGridList } from '../components/AlbumGridList';
import { SectionHeading } from '../components/SectionHeading';

export function Music() {
  return (
    <Section id="music">
      <Container fluid>
        <SectionHeading
          position={[null, null, null, 'absolute']}
          left={0}
          transform={[
            null,
            null,
            null,
            'translateX(calc(-100% + 1rem)) rotate(-90deg)'
          ]}
          transformOrigin={[null, null, null, 'top right']}
        >
          Music
        </SectionHeading>
        <AlbumGridList />
      </Container>
    </Section>
  );
}
